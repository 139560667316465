<template>
  <div class="digitsView" :style="viewStyle">
    <div class="digits" :style="styleObject">
      <div v-for="(item,index) in numArry " :key="index" :class="className[item]">
        <div v-if="item==','" class="dot">
          <p :style="siziColer">,</p>
        </div>

        <div v-else>
          <span class="d1" :style="styleColer"></span>
          <span class="d2" :style="styleColer"></span>
          <span class="d3" :style="styleColer"></span>
          <span class="d4" :style="styleColer"></span>
          <span class="d5" :style="styleColer"></span>
          <span class="d6" :style="styleColer"></span>
          <span class="d7" :style="styleColer"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListItem",
  props: {
    numAttr: {
      type: Object,
      default: function() {
        return { num: "4397", big: 1, styleColer: "#00ffe4" }; //数字 大小  颜色
      }
    }
  },
  data() {
    return {
      className: "zero one two three four five six seven eight nine".split(" "),
      numArry: []
    };
  },
  created() {
    this.numToArry();
  },
  watch:{
    "numAttr.num"(){
        this.numToArry()
    }
  },
  computed: {
    numWidth() {
      return this.numArry.length * 36; //计算的宽度
    },
    styleObject() {
      return {
        transform: "scale(" + this.numAttr.big + ")",

        width: this.numWidth + "px", // 重新设置宽度

               //让缩小的DIV 与父级DIV 位置重合
        top:  50 * (this.numAttr.big-1) / 2 + "px", // 50 为设置div高度
        left: this.numWidth * (this.numAttr.big-1) / 2 + "px"
      };
    },
    styleColer() {
      return {
        backgroundColor: this.numAttr.styleColer,
        borderColor: this.numAttr.styleColer
      };
    },
    siziColer() {
      return {
        color: this.numAttr.styleColer
      };
    },
    viewStyle() {
      return {
        //设置最外层DIV 让其与 内层 DIV 大小一样
        width: this.numArry.length * 36 * this.numAttr.big + "px",
        height: 54 * this.numAttr.big + "px"
      };
    }
  },
  methods: {
    numToArry() {

      if (typeof this.numAttr.num === "string") {

        this.numArry = this.numAttr.num.split("");

        return
      }

      var newString = String(this.numAttr.num).split("");

      var length =  newString.length

      // while (length-3 > 0 ) {  // 决定逗号的位置  3

      //  newString.splice( length-3 , 0 ,',')

      //   length-=3

      // }

      this.numArry = newString


    }
  }
};
</script>

<style >
.digits {
  text-align: left;
  position: relative;
}

.digits div {
  text-align: left;
  position: relative;
  width: 28px;
  height: 50px;
  display: inline-block;
  margin: 0 4px;
}
.digits div span {
  background-color: #00ffe4;
  border-color: #00ffe4;
}

.digits div span {
  opacity: 0;
  position: absolute;
  -webkit-transition: 0.25s;
  -moz-transition: 0.25s;
  transition: 0.25s;
}
.digits div span:before,
.digits div span:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 5px solid transparent;
}

.digits .d1 {
  height: 5px;
  width: 16px;
  top: 0;
  left: 6px;
}
.digits .d1:before {
  border-width: 0 5px 5px 0;
  border-right-color: inherit;
  left: -5px;
}
.digits .d1:after {
  border-width: 0 0 5px 5px;
  border-left-color: inherit;
  right: -5px;
}

.digits .d2 {
  height: 5px;
  width: 16px;
  top: 24px;
  left: 6px;
}
.digits .d2:before {
  border-width: 3px 4px 2px;
  border-right-color: inherit;
  left: -8px;
}
.digits .d2:after {
  border-width: 3px 4px 2px;
  border-left-color: inherit;
  right: -8px;
}

.digits .d3 {
  height: 5px;
  width: 16px;
  top: 48px;
  left: 6px;
}
.digits .d3:before {
  border-width: 5px 5px 0 0;
  border-right-color: inherit;
  left: -5px;
}
.digits .d3:after {
  border-width: 5px 0 0 5px;
  border-left-color: inherit;
  right: -5px;
}

.digits .d4 {
  width: 5px;
  height: 14px;
  top: 7px;
  left: 0;
}
.digits .d4:before {
  border-width: 0 5px 5px 0;
  border-bottom-color: inherit;
  top: -5px;
}
.digits .d4:after {
  border-width: 0 0 5px 5px;
  border-left-color: inherit;
  bottom: -5px;
}

.digits .d5 {
  width: 5px;
  height: 14px;
  top: 7px;
  right: 0;
}
.digits .d5:before {
  border-width: 0 0 5px 5px;
  border-bottom-color: inherit;
  top: -5px;
}
.digits .d5:after {
  border-width: 5px 0 0 5px;
  border-top-color: inherit;
  bottom: -5px;
}

.digits .d6 {
  width: 5px;
  height: 14px;
  top: 32px;
  left: 0;
}
.digits .d6:before {
  border-width: 0 5px 5px 0;
  border-bottom-color: inherit;
  top: -5px;
}
.digits .d6:after {
  border-width: 0 0 5px 5px;
  border-left-color: inherit;
  bottom: -5px;
}

.digits .d7 {
  width: 5px;
  height: 14px;
  top: 32px;
  right: 0;
}
.digits .d7:before {
  border-width: 0 0 5px 5px;
  border-bottom-color: inherit;
  top: -5px;
}
.digits .d7:after {
  border-width: 5px 0 0 5px;
  border-top-color: inherit;
  bottom: -5px;
}

/* 1 */
.digits div.one .d5,
.digits div.one .d7 {
  opacity: 1;
}

/* 2 */
.digits div.two .d1,
.digits div.two .d5,
.digits div.two .d2,
.digits div.two .d6,
.digits div.two .d3 {
  opacity: 1;
}

/* 3 */
.digits div.three .d1,
.digits div.three .d5,
.digits div.three .d2,
.digits div.three .d7,
.digits div.three .d3 {
  opacity: 1;
}

/* 4 */
.digits div.four .d5,
.digits div.four .d2,
.digits div.four .d4,
.digits div.four .d7 {
  opacity: 1;
}

/* 5 */
.digits div.five .d1,
.digits div.five .d2,
.digits div.five .d4,
.digits div.five .d3,
.digits div.five .d7 {
  opacity: 1;
}

/* 6 */
.digits div.six .d1,
.digits div.six .d2,
.digits div.six .d4,
.digits div.six .d3,
.digits div.six .d6,
.digits div.six .d7 {
  opacity: 1;
}

/* 7 */
.digits div.seven .d1,
.digits div.seven .d5,
.digits div.seven .d7 {
  opacity: 1;
}

/* 8 */
.digits div.eight .d1,
.digits div.eight .d2,
.digits div.eight .d3,
.digits div.eight .d4,
.digits div.eight .d5,
.digits div.eight .d6,
.digits div.eight .d7 {
  opacity: 1;
}

/* 9 */
.digits div.nine .d1,
.digits div.nine .d2,
.digits div.nine .d3,
.digits div.nine .d4,
.digits div.nine .d5,
.digits div.nine .d7 {
  opacity: 1;
}

/* 0 */
.digits div.zero .d1,
.digits div.zero .d3,
.digits div.zero .d4,
.digits div.zero .d5,
.digits div.zero .d6,
.digits div.zero .d7 {
  opacity: 1;
}

/* dot */
.digits div.dot {
  width: 100%;
  text-align: center;
}

.digits div.dot p {
  font-size: 40px;
  font-weight: 900;
  margin: 0;
}

</style>
