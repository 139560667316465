import request from '@/api/requestNew'
let defaultParam = {
};

export function getMethod(url, param) {
  // var url = '/tpridmp/process/' + processcode;
  return request({
    url: url,
    method: 'get',
    params: {
      ...defaultParam,
      ...param
    }
  })
}
export function postMethod(url, param) {
  return request({
    url: url,
    method: 'post',
    params: {
      ...defaultParam,
      ...param
    }
  })
}
