<template>
<div class="box">
  <div
    :id="echarts"
    class="echarts"
    ref="echarts"
  ></div>

</div>

</template>
<script>
import echarts from "echarts";
export default {
  props: {
    // 接收父组件传递过来的信息
    numbers: {
      type: Array,
    },
    percents: {
      type: Array,
    },
  },
  data() {
    return {
      // total:0
    };
  },
  methods: {
    drawChart() {
      // let title = '总数';
      var myChart = this.$echarts.init(document.getElementById(this.echarts));
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#283b56'
            }
          }
        },
        // legend: {
        //   data:['最新成交价', '预购队列']
        // },
        // toolbox: {
        //   show: true,
        //   feature: {
        //     dataView: {readOnly: false},
        //     restore: {},
        //     saveAsImage: {}
        //   }
        // },
        grid: {
          left: '3%',
          right: '3%',
          bottom: '4%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: ['>1500','1000-1500','500-1000','200-500','<200'],
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle:{
                // type:'dashed',
                color: ['#999'],
              }
            },
            splitLine:{
              show:false,
              lineStyle:{
                type:'dashed',
                color: ['#476086'],
              }
            },
            axisLabel: {
              interval: 0 ,
              rotate:-40,
              textStyle: {
                color: '#999',
                fontSize:'12'
              },
            },
          },
          {
            type: 'category',
            boundaryGap: true,
            data: '',
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle:{
                // type:'dashed',
                color: ['#999'],
              }
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            name: '客户数(户)',
            // max: 30,
            min: 0,
            boundaryGap: [0.2, 0.2],
            nameTextStyle:{
              color:"#999",
              fontSize:12,
              padding: [0, 0, 0, 16]
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle:{
                // type:'dashed',
                color: ['#999'],
              }
            },
            splitLine:{
              show:false,
              lineStyle:{
                type:'dashed',
                color: ['#476086'],
              }
            },
            axisLabel: {
              textStyle: {
                color: '#999',
                fontSize:'12'
              },
            }
          },
          {
            type: 'value',
            scale: true,
            name: '占比(%)',
            nameTextStyle:{
              color:"#ccc",
              fontSize:12,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle:{
                // type:'dashed',
                color: ['#ccc'],
              }
            },
            splitLine:{
              show:false,
              lineStyle:{
                type:'dashed',
                color: ['#476086'],
              }
            },
            axisLabel: {
              textStyle: {
                color: '#999',
                fontSize:'12'
              },
            },
            // max: 1200,
            min: 0,
            boundaryGap: [0.2, 0.2]
          }
        ],
        series: [
          {
            name: '预购队列',
            type: 'bar',
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: this.percents,
            barWidth : 12,
          itemStyle: {
        emphasis: {
        },
        normal: {
          // 设置柱子的颜色
          color: new echarts.graphic.LinearGradient(0,0,0,1,
              [
                {offset: 0, color: '#0EB2DE'},                 //柱图渐变色
                {offset: 1, color: '#6546B6'},                   //柱图渐变色
              ]
          ),
              // barBorderRadius: 5, // 柱子圆角设置
              // 以下为是否显示，显示位置和显示格式的设置了
              label: {
                show: true, //开启显示  柱子上的文字是否显示
                position: 'top', //在上方显示
                textStyle: { //数值样式
                  color: '#999',
                  fontSize: 16
                }
          }
        }
      }
          },
          {
            name: '占比',
            type: 'line',
            color:'#04EBF9',
            data: this.numbers,
            symbol: 'circle',     //设定为实心点
            symbolSize: 8,
            /*markPoint: {
              data: [
                {type: 'max', name: '最大值',textStyle: { //数值样式
                    color: '#999',
                    fontSize: 16
                  }},
                {type: 'min', name: '最小值',
                  textStyle: { //数值样式
                    color: '#999',
                    fontSize: 16
                  }}
              ]
            }*/
          }
        ]
      });
      setTimeout(function (){
        // 如果一个页面引入多次相同的子组件，只有一个生效，
          // window.onresize = function () {
          //   myChart.resize();
          // }
          // 可以同时生效
          window.addEventListener('resize', ()=> {
            myChart.resize();
          })
      },200)
    },
  },
  computed: {
    echarts() {
      return "echarts" + Math.random() * 100000;
    },
  },
  mounted: function () {
    const vm = this;
    vm.$nextTick(() => {
      vm.drawChart();
    });
  },
};
</script>
<style scoped>
.echarts{
  width: 100%;
  height: 100%;
}
.box{
  width: 100%;
  height: 100%;
  color:rgba(rgb(169, 211, 245), .3);
}
</style>
