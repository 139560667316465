<template>
<div class="box">
  <div
    :id="echarts"
    class="echarts"
    ref="echarts"
  ></div>

</div>

</template>
<script>
import echarts from "echarts";
export default {
  props: {
    // 接收父组件传递过来的信息
    profits: {
      type: Array,
    },
    BData: {
      type: Array,
    },
    total: {
      type: Number,
    },
  },
  data() {
    return {
      // total:0
    };
  },
  methods: {
    drawChart() {
      // let title = '总数';
      var myChart = this.$echarts.init(document.getElementById(this.echarts));
      myChart.setOption({
        /*tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#283b56'
            }
          }
        },*/
        // legend: {
        //   data:['最新成交价', '预购队列']
        // },
        // toolbox: {
        //   show: true,
        //   feature: {
        //     dataView: {readOnly: false},
        //     restore: {},
        //     saveAsImage: {}
        //   }
        // },
        grid: {
          left: '0%',
          right: '0%',
          bottom: '14%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: ['1','2','3','4','5','6','7','8','9','10','11','12'],
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle:{
                // type:'dashed',
                color: ['#999'],
              }
            },
            splitLine:{
              show:false,
              lineStyle:{
                type:'dashed',
                color: ['#476086'],
              }
            },
            axisLabel: {
              // interval: 0 ,
              // rotate:-40,
              textStyle: {
                color: '#999',
                fontSize:'12'
              },
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            name: '万元',
            // max: 10,
            min: 0,
            // boundaryGap: [0.2, 0.2],
            nameTextStyle:{
              color:"#999",
              fontSize:12,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle:{
                // type:'dashed',
                color: ['#999'],
              }
            },
            splitLine:{
              show:false,
              lineStyle:{
                type:'dashed',
                color: ['#476086'],
              }
            },
            axisLabel: {
              textStyle: {
                color: '#999',
                fontSize:'12'
              },
            }
          }
        ],
        series: [
          {
            name: '预购队列',
            type: 'bar',
            data: this.profits,
            barWidth : 10,
          itemStyle: {
        emphasis: {
        },
        normal: {
          // 设置柱子的颜色
          color: new echarts.graphic.LinearGradient(0,0,0,1,
              [
                {offset: 0, color: '#0EB2DE'},                 //柱图渐变色
                {offset: 1, color: '#6546B6'},                   //柱图渐变色
              ]
          ),
              // barBorderRadius: 5, // 柱子圆角设置
              // 以下为是否显示，显示位置和显示格式的设置了
              /*label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                textStyle: { //数值样式
                  color: '#999',
                  fontSize: 16
                }
          }*/
        }
      }
          }
        ]
      });
      setTimeout(function (){
        // 如果一个页面引入多次相同的子组件，只有一个生效，
          // window.onresize = function () {
          //   myChart.resize();
          // }
          // 可以同时生效
          window.addEventListener('resize', ()=> {
            myChart.resize();
          })
      },200)
    },
  },
  computed: {
    echarts() {
      return "echarts" + Math.random() * 100000;
    },
  },
  mounted: function () {
    const vm = this;
    vm.$nextTick(() => {
      vm.drawChart();
    });
  },
};
</script>
<style scoped>
.echarts{
  width: 100%;
  height: 100%;
}
.box{
  width: 100%;
  height: 100%;
  color:rgba(rgb(169, 211, 245), .3);
}
</style>
