<template>
<!-- 最外层div -->
  <div class="left-first">
    <!-- 标题div -->
    <div>
      <p>月度交易电量</p>
      <p>{{item.time}}</p>
    </div>      <!-- 标题div over-->
    <!-- 内容div -->
    <div >
      <!-- 内容左侧 -->
      <div class="left-first-left">
        <!-- 累计交易电量 -->
        <div>
          <p>累计交易电量(万kWh)</p>
          <p style="color: #9CC4CC;font-size: 20px">{{ item.total }}</p>
        </div>    <!-- 累计交易电量 over-->
        <!-- 最高最低 -->
        <div>
          <li>
            <p>{{item.minMonth}} 月交易最低(万kWh)</p>
            <p style="color: #9CC4CC;font-size: 20px">{{ item.min }}</p>
          </li>
          <li>
            <p>{{item.maxMonth}} 月交易最高(万kWh)</p>
            <p style="color: #9CC4CC;font-size: 20px">{{ item.max }}</p>
          </li>
        </div>    <!-- 最高最低 over-->


      </div>    <!-- 内容左侧 over-->

      <!-- 内容右侧 -->
      <div class="left-first-right">
        <LeftTopEcharts :yDatas="item.yDatas" :key="trem" v-if="item.yDatas"></LeftTopEcharts>
      </div>    <!-- 内容右侧 over-->

    </div>      <!-- 内容div over-->
  </div>
<!-- 最外层div over-->
</template>
<script>
import LeftTopEcharts from '../echarts/LeftTopEcharts'
import * as method from "@/api";
export default {
  components:{
    LeftTopEcharts
  },
  props:{
  },
  data(){
    return{
      item:{},
      trem: 1
    }
  },
  mounted() {
    this.monthElec();
    let vm = this
    setInterval(function (){
      vm.trem++;
    },4000)
  },
  methods:{
    monthElec() {
      method.getMethod('/sdyw/dashboard/month/elec', {
      }).then(res => {
        this.item = res
       this.item={max: 40000,
        maxMonth: "2",
        min: 35000,
        minMonth: "1",
        time: "2021.01.01-2021.01.31",
        total: 75000,
         yDatas:{
           changxie: [120, 232, 201, 234, 190, 230, 210,120, 132, 171, 184, 190],
           guapai: [50, 232, 201, 154, 190, 330, 410, 201, 154, 190, 330,113],
           heyue: [20, 300, 280, 334, 390, 330, 320,234, 201, 254, 290, 330,],
           jingjia: [120, 182, 191, 234, 290, 330, 310,220, 182, 191, 234, 290]
         }}
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .left-first{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* 定高 */
    height: 20.2rem;
  }
  /* 标题 */
  .left-first>div:first-child{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* border: 1px solid rgb(243, 245, 242); */
    /* 定高 */
    height: 2.125rem;
    color: #fff;
    font-size: .875rem;
    display: flex;
    line-height: 2.125rem;
    justify-content: space-between;
    padding:0rem 1.1rem 0rem ;
    background-color: rgba(58, 64, 92, 0.6);
  }
  .left-first>div:first-child>p:nth-child(1){
    color: #04EBF9;
  }
  .left-first>div:first-child>p:nth-child(2){
    color: #ACD8E1;
  }
  /* 内容 */
  .left-first>div:nth-child(2){
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* 定高 */
    height: 16.5rem;
    margin-top: .8rem;
    display: flex;
  }
  .left-first-left{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    flex-basis: 46.5%;
    color: #3F6979;
    font-size: 12px;
    /*text-align: left;*/
  }
  .left-first-left div:nth-of-type(1){
    text-align: left;
    padding: 10px 0 0 20px;
    p{
      line-height: 30px;
    }
  }
  .left-first-left div:nth-of-type(2){
    text-align: left;
    padding: 10px 0 0 20px;
    li{list-style: none}
    li:nth-of-type(2){margin-top: 10px}
    p{
      line-height: 26px;
    }
  }
  .left-first-right{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    flex-basis: 53.5%;
  }
  /* 内容内部 */
  .left-first-left>div{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /*定宽 */
    width: 13.5rem;
  }

  .left-first-left>div:first-child{
    background-image: url("../../static/image/left/月度1.png");
    background-repeat: no-repeat;
    /* 定高 */
    height: 6.5rem;
    margin-top: .5rem;
  }
  .left-first-left>div:nth-child(2){
    background-image: url("../../static/image/left/月度2.png");
    background-repeat: no-repeat;
    /* 定高 */
    height: 8.7rem;
  }
</style>
