<template>
<div class="box">
  <div
    :id="echarts"
    class="echarts"
    ref="echarts"
  ></div>

</div>

</template>
<script>
import echarts from "echarts";
export default {
  props: {
    // 接收父组件传递过来的信息
    BData: {
      type: Array,
    },
    item: {
      type: Object,
    },
  },
  data() {
    return {
      AData:[]
    };
  },
  methods: {
    drawChart() {
      var myChart = this.$echarts.init(document.getElementById(this.echarts));
      myChart.setOption({
        legend: {
          data: [ '售电均价','购电均价'],
          textStyle: {
            color: "#ccc",
            fontSize:12,
            align: "left"
          },
        },
        grid: {
          left: '0%',
          right: '6%',
          bottom: '14%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: '#fff',
                fontSize:'12'
              },
            },
            data: ['1', '2', '3', '4', '5', '6', '7','8', '9', '10', '11', '12']
          }
        ],
        yAxis: [
          {
            name: '万kWh',
            // max:1300,
            nameTextStyle:{
              color:"#ccc",
              fontSize:12,
            },
            boundaryGap: false,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine:{
              show:true,
              lineStyle:{
                type:'dashed',
                color: ['#476086'],
              }
            },
            axisLabel: {
              textStyle: {
                color: '#fff',
                fontSize:'12'
              },
            }
          }
        ],
        series: [
          {
            name: '购电均价',
            type: 'line',
            stack: '总量',
            // symbol: 'none',
            // smooth: true,  //折线平滑
            symbol: 'circle',     //设定为实心点
            symbolSize: 8,
            areaStyle: {opacity:0.3},
            color:'#04EBF9',
            data: this.item.priceClient,
          },
          {
            name: '售电均价',
            type: 'line',
            stack: '总量',
            // symbol: 'none',
            // smooth: true,  //折线平滑
            symbol: 'circle',     //设定为实心点
            symbolSize: 8,
            areaStyle: {opacity:0.3},
            color:'#FCA97A',
            data: this.item.priceCompany
          },
        ]
      });
      setTimeout(function (){
        // 如果一个页面引入多次相同的子组件，只有一个生效，
          // window.onresize = function () {
          //   myChart.resize();
          // }
          // 可以同时生效
          window.addEventListener('resize', ()=> {
            myChart.resize();
          })
      },200)
    },
  },
  computed: {
    echarts() {
      return "echarts" + Math.random() * 100000;
    },
  },
  mounted: function () {
    const vm = this;
    vm.$nextTick(() => {
      vm.drawChart();
    });
  },
};
</script>
<style lang="less" scoped>
.echarts{
  width: 100%;
  height: 100%;
}
.box{
  width: 100%;
  height: 300px;
  color: #A3D1FF;
  position: relative;
}
.box_title{
  position: absolute;
  right: 0;
  top:10px;
  font-size: 12px;
  color: #fff;
  display: flex;
  li{
    list-style: none;
    span{
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 4px;
    }
  }
}
</style>
