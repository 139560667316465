<template>
<div class="box">
  <div class="box_title">
    <div>
      <li><span style="background: #0E7391;"></span>年度长协</li>
      <li><span style="background: #135D52;margin-top: 12px;"></span>挂牌交易</li>
    </div>
    <div>
      <li style="margin-left: 24px;"><span style="background: #47327F;"></span>月度竞价</li>
      <li style="margin-left: 24px;"><span style="background: #C1815D;margin-top: 12px;"></span>合约转让</li>
    </div>
  </div>
  <div
    :id="echarts"
    class="echarts"
    ref="echarts"
  ></div>

</div>

</template>
<script>
import echarts from "echarts";
export default {
  props: {
    // 接收父组件传递过来的信息
    yDatas: {
      type: Object,
    },
  },
  data() {
    return {
      AData:[]
    };
  },

  computed: {
    echarts() {
      return "echarts" + Math.random() * 100000;
    },
  },
  mounted: function () {
    const vm = this;
    vm.$nextTick(() => {
      vm.drawChart();
    });
  },
  methods: {
    drawChart() {
      var myChart = this.$echarts.init(document.getElementById(this.echarts));
      myChart.setOption({
        // legend: {
        //   data: ['邮件营销', '联盟广告', '视频广告', '直接访问']
        // },
        grid: {
          left: '0%',
          right: '2%',
          bottom: '14%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: '#fff',
                fontSize:'12'
              },
            },
            data: ['1', '2', '3', '4', '5', '6', '7','8', '9', '10', '11', '12']
          }
        ],
        yAxis: [
          {
            name: '万kWh',
            // max:1300,
            nameTextStyle:{
              color:"#ccc",
              fontSize:12,
            },
            boundaryGap: false,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine:{
              show:true,
              lineStyle:{
                type:'dashed',
                color: ['#476086'],
              }
            },
            axisLabel: {
              textStyle: {
                color: '#fff',
                fontSize:'12'
              },
            }
          }
        ],
        series: [
          {
            name: '年度长协',
            type: 'line',
            stack: '总量',
            symbol: 'none',
            smooth: true,  //折线平滑
            areaStyle: {opacity:1},
            color:'#0E7391',
            // data: [120, 232, 201, 234, 190, 230, 210,120, 132, 171, 184, 190]
            data: this.yDatas.changxie
          },
          {
            name: '月度竞价',
            type: 'line',
            stack: '总量',
            symbol: 'none',
            smooth: true,  //折线平滑
            areaStyle: {opacity:1},
            color:'#47327F',
            // data: [120, 182, 191, 234, 290, 330, 310,220, 182, 191, 234, 290]
            data: this.yDatas.jingjia
          },
          {
            name: '挂牌交易',
            type: 'line',
            stack: '总量',
            symbol: 'none',
            smooth: true,  //折线平滑
            areaStyle: {opacity:1},
            color:'#135D52',
            // data: [50, 232, 201, 154, 190, 330, 410, 201, 154, 190, 330,113]讲解
            data: this.yDatas.guapai
          },
          {
            name: '合约转让',
            type: 'line',
            stack: '总量',
            symbol: 'none',
            smooth: true,  //折线平滑
            areaStyle: {opacity:1},
            color:'#C1815D',
            // data: [20, 300, 280, 334, 390, 330, 320,234, 201, 254, 290, 330,]
            data: this.yDatas.heyue
          }
        ]
      });
      setTimeout(function (){
        // 如果一个页面引入多次相同的子组件，只有一个生效，
          // window.onresize = function () {
          //   myChart.resize();
          // }
          // 可以同时生效
          window.addEventListener('resize', ()=> {
            myChart.resize();
          })
      },200)
    },
  },
};
</script>
<style lang="less" scoped>
.echarts{
  width: 100%;
  height: 100%;
}
.box{
  width: 100%;
  height: 300px;
  color: #A3D1FF;
  position: relative;
}
.box_title{
  position: absolute;
  right: 0;
  top:10px;
  font-size: 12px;
  color: #fff;
  display: flex;
  li{
    list-style: none;
    span{
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 4px;
    }
  }
}
</style>
