<template>
<!-- 最外层div -->
  <div class="left-third">
    <!-- 标题div -->
    <div>
      <p>客户签约电量密度分布</p>
<!--      <p>{{item.time}}</p>-->
    </div>      <!-- 标题div over-->
    <!-- 内容div -->
    <div >
      <!-- 内容左侧 -->
      <div class="left-third-left">
        <!-- 区间1 -->
        <div>
            <p>{{item.maxRange}}区间签约用户最多(户)</p>
            <p style="color: #9CC4CC;font-size: 20px">{{item.max}} <span style="float: right;font-size: 14px">{{ item.maxPercent }}%</span></p>
        </div>    <!-- 区间1 over-->
        <!-- 区间2 -->
        <div>
<!--          <p>{{item.maxRange}}区间签约电量最多(万kWh)</p>-->
          <p>{{item.minRange}}区间签约用户最少(户)</p>
          <p style="color: #9CC4CC;font-size: 20px">{{item.min}} <span style="float: right;font-size: 14px">{{item.minPercent}}%</span></p>
        </div>    <!-- 区间2 over-->


      </div>    <!-- 内容左侧 over-->

      <!-- 内容右侧 -->
      <div class="left-third-right">
        <LeftBottomEcharts :numbers="item.numbers" :percents="item.percents" :key="trem" v-if="item.numbers"></LeftBottomEcharts>
      </div>    <!-- 内容右侧 over-->

    </div>      <!-- 内容div over-->
  </div>
<!-- 最外层div over-->
</template>
<script>
import LeftBottomEcharts from '../echarts/LeftBottomEcharts'
import * as method from "@/api";
export default {
  components:{
    LeftBottomEcharts
  },
  props:{
  },
  data(){
    return{
      item:{},
      trem:1
    }
  },
  mounted() {
    this.density()
    let vm = this
    setInterval(function (){
      vm.trem++;
    },5000)
  },
  methods:{
    density() {
      method.getMethod('/sdyw/analysis/density', {
      }).then(res => {
        this.item = res
        this.item = {
          max: 8,
          maxPercent: 53,
          maxRange: ">1500",
          min: 0,
          minPercent: 0,
          minRange: "200-500",
          numbers: [8,3,3,0,1],
          percents: [53,20,20,0 , 7],
          time: "2021.01.01-2021.01.31",
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .left-third{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* border: 1px solid rgb(83, 221, 3); */
    /* 定高 */
    height: 20.2rem;
  }
  /* 标题 */
  .left-third>div:first-child{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* border: 1px solid rgb(243, 245, 242); */
    /* 定高 */
    height: 2.125rem;
    color: #fff;
    font-size: .875rem;
    display: flex;
    line-height: 2.125rem;
    justify-content: space-between;
    padding:0rem 1.1rem 0rem ;
    border-left:3px solid #04EBF9;
    background-color: rgba(58, 64, 92, 0.6);
  }
  .left-third>div:first-child>p:nth-child(1){
    color: #04EBF9;
  }
  .left-third>div:first-child>p:nth-child(2){
    color: #ACD8E1;
  }
  /* 内容 */
  .left-third>div:nth-child(2){
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* border: 1px solid rgb(243, 245, 242); */
    /* 定高 */
    height: 16.5rem;
    margin-top: .8rem;
    display: flex;
  }
  .left-third-left{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    flex-basis: 46.5%;
    color: #738F9B;
    font-size: 12px;
  }
  .left-third-left div{
    text-align: left;
    padding: 10px 20px 0 20px;
  p{
    line-height: 30px;
  }
  }
  .left-third-right{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    flex-basis: 53.5%;
  }
  /* 内容内部 */
  .left-third-left>div{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /*定宽 */
    width: 13.5rem;
  }

  .left-third-left>div:first-child{
    background-image: url("../../static/image/left/签约电量1.png");
    background-repeat: no-repeat;
    /* 定高 */
    height: 6.5rem;
    margin-top: .5rem;
  }
  .left-third-left>div:nth-child(2){
    background-image: url("../../static/image/left/签约电量2.png");
    background-repeat: no-repeat;
    /* 定高 */
    height: 5.9rem;
  }
</style>
