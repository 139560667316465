<template>
<!-- 最外层div -->
  <div class="right-first">
    <!-- 标题div -->
    <div>
      <p>合同价格分析</p>
      <p>{{item.time}}</p>
    </div>      <!-- 标题div over-->
    <!-- 内容div -->
    <div >
      <!-- 内容左侧 -->
      <div class="right-first-left">

        <RightTopEcharts :item="item" :key="trem" v-if="item.priceCompany && item.priceClient"></RightTopEcharts>
      </div>    <!-- 内容左侧 over-->

      <!-- 内容右侧 -->
      <div class="right-first-right">
         <!-- 均价1 -->
        <div>
          <li>
            <p>{{item.companyMaxMonth}} 月售电均价最高(元/kWh)</p>
            <p style="color: #9CC4CC;font-size: 20px">{{item.companyMax}}</p>
          </li>
          <li>
            <p>{{item.companyMinMonth}} 月售电均价最低(元/kWh)</p>
            <p style="color: #9CC4CC;font-size: 20px">{{item.companyMin}}</p>
          </li>

        </div>    <!-- 均价1 over-->
        <!-- 均价2 -->
        <div>
          <li>
            <p>{{item.clientMaxMonth}} 月购电均价最高(元/kWh)</p>
            <p style="color: #9CC4CC;font-size: 20px">{{item.clientMax}}</p>
          </li>
          <li>
            <p>{{item.clientMinMonth}} 月购电均价最低(元/kWh)</p>
            <p style="color: #9CC4CC;font-size: 20px">{{item.clientMin}}</p>
          </li>
        </div>    <!-- 均价2 over-->
      </div>    <!-- 内容右侧 over-->

    </div>      <!-- 内容div over-->
  </div>
<!-- 最外层div over-->
</template>
<script>
import RightTopEcharts from '../echarts/RightTopEcharts'
import * as method from "@/api";
export default {
  components:{
    RightTopEcharts
  },
  props:{
  },
  data(){
    return{
      item:{},
      trem:1
    }
  },
  mounted() {
    this.price();
    let vm = this
    setInterval(function (){
      vm.trem++;
    },6000)
  },
  methods:{
    price() {
      method.getMethod('/sdyw/contract/analysis/avg/price', {
      }).then(res => {
        this.item = res
        this.item = {
          clientMax: 362.86,
          clientMaxMonth: "1",
          clientMin: 362.5,
          clientMinMonth: "2",
          companyMax: 350,
          companyMaxMonth: "2",
          companyMin: 350,
          companyMinMonth: "1",
          priceClient: [50, 232, 201, 154, 190, 330, 410, 201, 154, 190, 330,113],
          priceCompany: [20, 300, 280, 334, 390, 330, 320,234, 201, 254, 290, 330,],
          time: "2021.01.01-2021.01.31",
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .right-first{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* 定高 */
    height: 20.2rem;
  }
  /* 标题 */
  .right-first>div:first-child{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* border: 1px solid rgb(243, 245, 242); */
    /* 定高 */
    height: 2.125rem;
    color: #fff;
    font-size: .875rem;
    display: flex;
    line-height: 2.125rem;
    justify-content: space-between;
    padding:0rem 1.1rem 0rem ;
    border-left:3px solid #04EBF9;
    background-color: rgba(58, 64, 92, 0.6);
  }
  .right-first>div:first-child>p:nth-child(1){
    color: #04EBF9;
  }
  /* 内容 */
  .right-first>div:nth-child(2){
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* 定高 */
    height: 16.5rem;
    margin-top: .8rem;
    display: flex;
  }
  .right-first-left{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    flex-basis: 53.5%;
  }
  .right-first-right{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    flex-basis: 46.5%;
    color: #738F9B;
    font-size: 12px;
  }
  .right-first-right div{
    text-align: left;
    padding: 10px 20px 0 20px;
    li{
      list-style: none;
    }
    li:nth-of-type(2){margin-top: 4px;}
    p{
      line-height: 24px;
    }
  }

  /* 内容内部 */
  .right-first-right>div{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /*定宽 */
    width: 13.5rem;
  }

  .right-first-right>div:first-child{
    background-image: url("../../static/image/right/合同价格1.png");
    background-repeat: no-repeat;
    /* 定高 */
    height: 7.5rem;
    margin-top: .5rem;
  }
  .right-first-right>div:nth-child(2){
    background-image: url("../../static/image/right/合同价格2.png");
    background-repeat: no-repeat;
    /* 定高 */
    height: 7.5rem;
    margin-top: .5rem;
  }
  .right-first>div:first-child>p:nth-child(2){
    color: #ACD8E1;
  }
</style>
