<template>
<div class="box">
  <div
    :id="echarts"
    class="echarts"
    ref="echarts"
  ></div>

</div>

</template>
<script>
import echarts from "echarts";
export default {
  props: {
    // 接收父组件传递过来的信息
    AData: {
      type: Array,
    },
    item: {
      type: Object,
    },
    total: {
      type: Number,
    },
  },
  data() {
    return {
      // total:0
    };
  },
  methods: {
    drawChart() {
      // let title = '总数';
      var myChart = this.$echarts.init(document.getElementById(this.echarts));
      myChart.setOption({
        tooltip:{
        enterable:true
        },
        series: [
          {
            // color:[ "#FFB156","#0E6F8C","#1E9281","#6748B8"],
            silent: true,
            name: "",
            type: "pie",
            avoidLabelOverlap: true,   //是否启用防止标签重叠策略
            radius: ["40%", "54%"],
            center: ["50%", "52%"],
            // avoidLabelOverlap: false,
            hoverAnimation: false,
            label: {
                padding: [0, 0, 0, 0],
                show: true,
                position: 'outside',
                formatter: [
                    // '{name|{b}}',//引导线上面文字
                    // '{text|{c}}','{text|{d}}{percent|%}', //引导线下面文字
                    '{text|{d}}{percent|%}', //引导线下面文字
                ].join("   "), //用\n来换行
                rich: {
                    name: {
                        fontSize: 12,
                        color:'#ccc',
                        padding: [0, 0],
                    },
                    text: {
                        fontSize: 12,
                        color:'#fff',
                    },
                    percent:{
                        fontSize: 12,
                        padding: [0, 0],
                        color:'#ccc'
                    }
                }
            },
            labelLine: {
                normal: {
                    length: 20,
                    length2: 0,
                    align: 'right',
                    lineStyle: {
                        width: 0,
                    }
                }
            },
            emphasis: {
              label: {
                show: false, //让中间动态文字不显示
              },
            },
            data: [
              {value: this.item.guapai, name: '挂牌交易' ,itemStyle: {
                  normal: {//颜色渐变
                    color: new echarts.graphic.LinearGradient( //解决颜色渐变
                        0, 0, 0, 1,
                        [{offset: 0, color: '#03CCD8'},
                          {offset: 1, color: '#0E6F8C'}])
                  }}
                  },
              {value: this.item.heyue, name: '合约转让',itemStyle: {
                  normal: {//颜色渐变
                    color: new echarts.graphic.LinearGradient(
                        0, 0, 0, 1,
                        [{offset: 0, color: '#6748B8'},
                          {offset: 1, color: '#47327F'}])
                  }}},
              {value: this.item.changxie, name: '年度长协', itemStyle: {
                  normal: {//颜色渐变
                    color: new echarts.graphic.LinearGradient(
                        0, 0, 0, 1,
                        [{offset: 0, color: '#1E9281'},
                          {offset: 1, color: '#135D52'}])
                  }}},
              {value: this.item.jingjia , name: '月度竞价',itemStyle: {
                  normal: {//颜色渐变
                    color: new echarts.graphic.LinearGradient(
                        0, 0, 0, 1,
                          [{offset: 0, color: '#FFB156'},
                          {offset: 1, color: '#C1815D'}])
                  }}}
            ],
          },
        ],
      });
      setTimeout(function (){
        // 如果一个页面引入多次相同的子组件，只有一个生效，
          // window.onresize = function () {
          //   myChart.resize();
          // }
          // 可以同时生效
          window.addEventListener('resize', ()=> {
            myChart.resize();
          })
      },200)
    },
  },
  computed: {
    echarts() {
      return "echarts" + Math.random() * 100000;
    },
  },
  mounted: function () {
    const vm = this;
    vm.$nextTick(() => {
      vm.drawChart();
    });
  },
};
</script>
<style scoped>
.echarts{
  width: 100%;
  height: 100%;
}
.box{
  width: 100%;
  height: 100%;
  color:rgba(rgb(169, 211, 245), .3);
}
</style>
