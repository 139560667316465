<template>
<div class="box">
  <!--<ul>
    <li v-for="i in 5"></li>
  </ul>-->
  <div
    :id="echarts"
    class="echarts"
    ref="echarts"
  ></div>

</div>

</template>
<script>
import echarts from "echarts";
export default {
  props: {
    // 接收父组件传递过来的信息
    item: {
      type: Array,
    },
    item1: {
      type: Array,
    },
    item2: {
      type: Array,
    },
    BData: {
      type: Array,
    },
    total: {
      type: Number,
    },
  },
  data() {
    return {
      // total:0
    };
  },
  methods: {
    drawChart() {
      // let title = '总数';
      var myChart = this.$echarts.init(document.getElementById(this.echarts));
      myChart.setOption({
        tooltip: {},
        radar: {
          // shape: 'circle',
          center: ['50%', '50%'],
          // 圆中心坐标，数组的第一项是横坐标，第二项是纵坐标。[ default: ['50%', '50%'] ]
          radius: 80,
          // 圆的半径，数组的第一项是内半径，第二项是外半径。
          startAngle: 90,
          name: {
            textStyle: {
              color: '#738F9B',
              // backgroundColor: '#999',
              borderRadius: 3,
              padding: [0, -8,0,-2]
            }
          },
          indicator: this.item1,
          splitArea : {
            show : true,
            areaStyle : {
              color: ['rgb(13 20 29)', '','', '', '',]
              // 图表背景网格的颜色
            }
          },
          splitLine : {
            show : true,
            lineStyle : {
              width : 1,
              color : ['#738F9B']
              // 图表背景网格线的颜色
            }
          }
        },
        series: [{
          name: '',
          type: 'radar',
          // areaStyle: {normal: {}},
          data: [
            {
              value: this.item2,
              name: '',
              type: 'radar',
              radarIndex: 2,
              areaStyle: {
                color: '#236FB0',
                opacity: 0.8
              },
              itemStyle: {
                normal: {
                  color: '#236FB0',
                  lineStyle: {
                    color: 'rgba(255,225,0,.3)',
                  },
                },
              }

            }
          ]
        }]
      });
      setTimeout(function (){
        // 如果一个页面引入多次相同的子组件，只有一个生效，
          // window.onresize = function () {
          //   myChart.resize();
          // }
          // 可以同时生效
          window.addEventListener('resize', ()=> {
            myChart.resize();
          })
      },200)
    },
  },
  computed: {
    echarts() {
      return "echarts" + Math.random() * 10;
    },
  },
  mounted: function () {
    const vm = this;
    vm.$nextTick(() => {
      vm.drawChart();
    });
  },
};
</script>
<style scoped>
.echarts{
  width: 100%;
  height: 100%;
}
.box{
  width: 100%;
  height: 100%;
  color:rgba(rgb(169, 211, 245), .3);
  position: relative;
}
ul{
  position: absolute;
}
li{
  list-style: none;
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
li:nth-of-type(1) {background: #135D52;top: 26px;left: 98px;}
li:nth-of-type(2) {background: #C1815D; top: 98px;}
li:nth-of-type(3) {background: #3F6979; top: 204px;left: 34px;}
li:nth-of-type(4) {background: #47327F; left: 174px;top: 204px;}
li:nth-of-type(5) {background: #9C8C03;     left: 208px;top: 98px;}
</style>
