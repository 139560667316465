<template>
  <!-- 最外层div -->
  <div class="map">
    <!-- 解决高度坍塌 -->
    <table></table>
    <!-- 半透明底内容 -->
    <div>
      <!-- 图例 -->
      <p>
        <span>●</span>签约客户
      </p>
      <!-- 图例 over-->
      <!-- 拼图 -->
      <div class="blue">
        <!-- 遍历产生底部蓝色拼图 -->
        <div v-for="i of 13" :key="i" v-show="blueShow[i-1]">
          <!-- !!动态改变图片 !!!-->
          <img :src="require(`../../static/image/center/map/${i}.png`)" alt="sorry 图裂了">
        </div>
      </div>    <!-- 拼图 over-->

      <!-- 地名 圆圈  -->
      <div class="contain">
        <!-- 遍历产生不同城市的部分 -->
        <div v-for="(i,index) of city" :key="index" @mouseover="circle_in($event,index)" @mouseout="circle_out($event,index)">
          <!-- 地名和圆圈 -->
          <div class="T-C">
            <p>{{i}}</p>
            <!-- 圆圈部分 -->
            <div class="circle" ref="circle">
              <p>{{customs[index].custom}}</p>
            </div>      <!-- 圆圈部分 over-->
          </div>    <!-- 地名和圆圈 over-->

        </div>     <!-- 遍历产生不同城市的部分 over-->
      </div>      <!-- 地名 圆圈 over-->

      <!-- 弹出框 -->
      <div class="square-all">
        <!-- 遍历框框部分 -->
          <div class="square" v-for="(i,index) of city" :key="index" v-show="squareShow[index]">
            <!-- 上部 -->
            <div class="square-top">
              <div>
                {{i}}区域：
              </div>
              <div></div>
            </div>
            <!-- 下部 -->
            <div>
              <p>
                客户数量：<span>{{customs[index].custom}}</span> 户
              </p>
              <p>
                签约电量：<span>{{customs[index].electric}}</span> 万kWh
              </p>
            </div>
          </div>      <!-- 框框部分 over-->
      </div>
      <!-- 弹出框 over-->

    </div>    <!-- 半透明底内容 over-->
  </div>    <!-- 最外层div over-->
</template>


<script>
// 引入axios请求组件
import * as method from "@/api";
  export default{
    data(){
      return{
        // 控制底部蓝色
        // 0.徐州 1.连云港 2.宿迁 3.淮安 4.盐城 5.扬州 6.泰州 7.南京 8.镇江 9.常州 10.无锡 11.苏州 12.南通
        blueShow:[
          false,false,false,false,false,false,false,false,false,false,false,false,false,
        ],
        city:[
          "徐州","连云港","宿迁","淮安","盐城","扬州","泰州","南京","镇江","常州","无锡","苏州","南通"
        ],
        squareShow:[
          false,false,false,false,false,false,false,false,false,false,false,false,false,
        ],
        // 模拟客户数和签约电量
        customs:[
          {electric:"",custom:"",city:"徐州"},
          {electric:"",custom:"",city:"连云港"},
          {electric:"",custom:"",city:"宿迁"},
          {electric:"",custom:"",city:"淮安"},
          {electric:"",custom:"",city:"盐城"},
          {electric:"",custom:"",city:"扬州"},
          {electric:"",custom:"",city:"泰州"},
          {electric:"",custom:"",city:"南京"},
          {electric:"",custom:"",city:"镇江"},
          {electric:"",custom:"",city:"常州"},
          {electric:"",custom:"",city:"无锡"},
          {electric:"",custom:"",city:"苏州"},
          {electric:"",custom:"",city:"南通"},
        ]
      }
    },
    methods:{
      // 圆圈部分的移入事件
      circle_in(e,i){
        e.stopPropagation();
        // 如果是有客户数的才显示方框
        if(this.customs[i].custom !=""){
          this.squareShow.splice(i,1,true);
        }
        this.blueShow.splice(i,1,true);
      },
      circle_out(e,i){
        e.stopPropagation();
        if(this.customs[i].custom !=""){
          this.squareShow.splice(i,1,false);
        }
        this.blueShow.splice(i,1,false);
      },
      // 获取客户数量 并根据数量确定圈圈大小
      get_custom(){
        // 小于100 2.5rem  每多100增加.5rem
      // 发送请求获取数据 取出数据 放入data中对象/数组
        method.getMethod("/sdyw/analysis/map",{
        }).then(res=>{
          // 创建容器
          let objHolder={}
          for(let c=0;c<this.customs.length;c++){
            objHolder={}
            // 获取界面上城市顺序
            let getCity=this.customs[c].city;
            // 放入临时对象容器
            objHolder.city=getCity;
            // 找到结果中对应的
             for (let obj of res) {
               if(obj.areaName.indexOf(getCity)!=-1){
                objHolder.electric=obj.totalNumber;
                objHolder.custom=obj.totalCount;
                this.customs.splice(c,1,objHolder)
               }
             }
          }
          this.customs = [
              {
                "city": "徐州",
                "electric": 1300,
                "custom": "1"
              },
                  {
                    "city": "连云港",
                    "electric": 50,
                    "custom": "1"
                  },
                  {
                    "electric": "",
                    "custom": "",
                    "city": "宿迁"
                  },
                  {
                    "city": "淮安",
                    "electric": 10000,
                    "custom": "1"
                  },
                  {
                    "city": "盐城",
                    "electric": 740,
                    "custom": "1"
                  },
                  {
                    "city": "扬州",
                    "electric": 10000,
                    "custom": "1"
                  },
                  {
                    "electric": "",
                    "custom": "",
                    "city": "泰州"
                  },
                  {
                    "city": "南京",
                    "electric": 5000,
                    "custom": "2"
                  },
                  {
                    "city": "镇江",
                    "electric": 14500,
                    "custom": "4"
                  },
                  {
                    "electric": "",
                    "custom": "",
                    "city": "常州"
                  },
                  {
                    "city": "无锡",
                    "electric": 6560,
                    "custom": "3"
                  },
                  {
                    "electric": "",
                    "custom": "",
                    "city": "苏州"
                  },
                  {
                    "city": "南通",
                    "electric": 3000,
                    "custom": "1"
                  }
              ]
          setTimeout(()=>{
            let count=0;
          // 根据里面的数值来确认圆圈大小
          let circles=this.$refs.circle;
          for (let div of circles) {
            // 若没有客户 则圆圈隐藏
            if(div.innerText==""){
              div.style.visibility="hidden";
            }
            count= parseInt(div.innerText);
            if(count>=100){
              div.style.width=`${0.5*((count-100)/100)+2.5}rem`
              // console.log(div.style.width);
              div.style.height=`${0.5*((count-100)/100)+2.5}rem`
            }else{
              div.style.width="2.5rem";
              div.style.height="2.5rem";
            }
          }
          },150)

        })

      }
    },
    mounted(){
      this.get_custom();
    }
  }
</script>

<style scoped>
  .map{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* border: 1px solid rgb(72, 245, 4); */
    /* 定高定宽 */
    height: 52rem;
    width: 47rem;
    background-image: url("../../static/image/center/map/bg_border.png");
    background-repeat: no-repeat;
    margin: 0 auto;
    margin-top: .6rem;
    padding: .9rem 1.1rem .9rem
  }
  .map>div{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* border: 1px solid rgb(218, 230, 213); */
    background-image: url("../../static/image/center/map/map.png");
    background-repeat: no-repeat;
    background-position: 7.1rem;
    /* 定高 */
    height: 100%;
    background-color: rgba(23, 39, 53, 0.5);
    /* 相对定位 */
    position: relative;
  }
  .map>div>p{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-size: .875rem;
    color: #fff;
    text-align: right;
    padding-right: 2rem;
    padding-top:2rem ;
  }
  .map>div>p>span{
    color:#FFB866;
    margin-right:.5rem ;
    font-size: 1.3rem;
  }
  /* 浅蓝拼图 */
  .blue>div{
    /* 绝对定位 */
    position: absolute;
  }
  .blue>div:first-child{
    left: 7rem;
    top: 1.1rem;
  }
  .blue>div:nth-child(2){
    right: 12.9rem;
    top: 7.2rem;
  }
  .blue>div:nth-child(3){
    left: 13.3rem;
    top: 12.159rem;
  }
  .blue>div:nth-child(4){
    left: 14rem;
    top: 16.05rem;
  }
  .blue>div:nth-child(5){
    right: 10rem;
    top: 14.6rem;
  }
  .blue>div:nth-child(6){
    left: 17.5rem;
    bottom: 15.8rem;
  }
  .blue>div:nth-child(7){
    right: 14.2rem;
    bottom: 11.5rem;
  }
  .blue>div:nth-child(8){
    left: 11.6rem;
    bottom: 9.1rem;
  }
  .blue>div:nth-child(9){
    left: 15.3rem;
    bottom: 11.3rem;
  }
  .blue>div:nth-child(10){
    left: 15.1rem;
    bottom: 6.8rem;
  }
  .blue>div:nth-child(11){
    left: 17.1rem;
    bottom: 6rem;
  }
  .blue>div:nth-child(12){
    right: 12.2rem;
    bottom: .8rem;
  }
  .blue>div:nth-child(13){
    right: 7.35rem;
    bottom: 3.6rem;
  }
  /* 地名 圆圈 弹出框 */
  .contain>div{
    /* border: 1px solid rgb(226, 21, 21); */
    /* 绝对定位 */
    position: absolute;
  }
  .T-C:hover{
    cursor: pointer;
  }
  .T-C>p{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    color: #fff;
    font-size: .875rem;
    text-align: left;
    margin-bottom: .5rem;
    padding-left:.35rem ;
  }
  .circle{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    color: #fff;
    border: 2px solid rgba(224, 162, 27, 0.6);
    box-shadow: .08rem .08rem .8rem .05rem rgba(245, 199, 73,0.7) inset;
    border-radius: 50%;
    /* 相对定位 */
    position: relative;
    /* 默认是小于100的大小 */
    /*width: 2.5rem;
    height: 2.5rem;*/
    -webkit-animation: warn 3s ease-out;
    -moz-animation: warn 3s ease-out;
    animation: warn 3s ease-out;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
  .circle>p{
    /* background-color: blue; */
    /* 永恒居中 */
    /* 绝对定位 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  /* 框框 */
  .square,.square>div,.square-top>div{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* border: 1px solid rgb(218, 230, 213); */

  }
  .square{
    /* border: 1px solid rgb(218, 230, 213); */
    /* 绝对定位 */
    position: absolute;
    font-size: .875rem;
    color: #fff;
  }
  .square-top{
    display: flex;
    /* border: 1px solid rgb(81, 233, 15); */
  }
  .square-top>div:first-child{
    /* border: 1px solid rgb(83, 235, 18); */
    background-image: url("../../static/image/center/map/square_bg.png");
    background-repeat: no-repeat;
    /* 定宽定高 */
    height: 3.25rem;
    width: 7.55rem;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding-top:.7rem ;
    text-align: left;
    padding-left: 1rem;
  }
  .square-top>div:nth-child(2){
    /* 定高 */
    height: 1.73rem;
    align-self: flex-end;
    border-top: 1px solid rgb(171,184,185,.8);
    border-right: 1px solid rgb(171,184,185,.8);
    border-top-right-radius: 5%;
    flex-grow: 1;
    background-color: rgba(0,0,0,.8);
  }
  .square>div:nth-child(2){
    border-right: 1px solid rgba(187, 196, 197, 0.8);
    border-left: 1px solid rgb(187, 196, 197, 0.8);
    border-bottom: 1px solid rgb(187, 196, 197, 0.8);
    background-color:  rgba(0,0,0,.8);
    /* 定高 */
    height: 6rem;
  }
  .square>div:nth-child(2)>p{
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: left;
  }
   .square>div:nth-child(2)>p>span{
     font-size: 1.25rem;
     font-weight: 600;
   }
  .square>div:nth-child(2)>p:first-child{
    margin-bottom: 1rem;
  }
  /* 标题和圆圈位置 */
  .contain>div:first-child{
    top:8.6rem;
    left:13.5rem;
  }
  .contain>div:nth-child(2){
    top:10.2rem;
    right:16rem;
  }
  .contain>div:nth-child(3){
    top:15.5rem;
    left:19.5rem;
  }
  .contain>div:nth-child(4){
    top:20rem;
    left:21.8rem;
  }
  .contain>div:nth-child(5){
    bottom:22.5rem;
    right:12.5rem;
  }
  .contain>div:nth-child(6){
    bottom:17.5rem;
    left:21.5rem;
  }
  .contain>div:nth-child(7){
    bottom:18rem;
    right:17rem;
  }
  .contain>div:nth-child(8){
    bottom:14.2rem;
    left:14.5rem;
  }
  .contain>div:nth-child(9){
    bottom:13rem;
    left:20rem;
  }
  .contain>div:nth-child(10){
    bottom:9rem;
    left:16rem;
  }
  .contain>div:nth-child(11){
    bottom:7.5rem;
    left:24rem;
  }
  .contain>div:nth-child(12){
    bottom:2rem;
    right:16rem;
  }
  .contain>div:nth-child(13){
    bottom:9.5rem;
    right:12rem;
  }
  /* 框框位置 */
  .square-all>div:nth-child(1){
    top:13.4rem;
    left:13.5rem;
  }
  .square-all>div:nth-child(2){
    top:15rem;
    right:9rem;
  }
  .square-all>div:nth-child(3){
    top:20.3rem;
    left:19.5rem;
  }
  .square-all>div:nth-child(4){
    top:24.8rem;
    left:21.8rem;
  }
  .square-all>div:nth-child(5){
    bottom:12.3rem;
    right:4.5rem;
  }
  .square-all>div:nth-child(6){
    bottom:7.5rem;
    left:21.5rem;
  }
  .square-all>div:nth-child(7){
    bottom:7.8rem;
    right:9rem;
  }
  .square-all>div:nth-child(8){
    bottom:4.2rem;
    left:14.5rem;
  }
  .square-all>div:nth-child(9){
    bottom:2.8rem;
    left:20rem;
  }
  .square-all>div:nth-child(10){
    bottom:-.5rem;
    left:15.5rem;
  }
  .square-all>div:nth-child(11){
    bottom:.0rem;
    left:28.2rem;
  }
  .square-all>div:nth-child(12){
    bottom:0rem;
    right:3.8rem;
  }
  .square-all>div:nth-child(13){
    bottom:3rem;
    right:-.5rem;
  }
  @keyframes warn {
    0% {
      transform: scale(0.6);
      opacity: 0.6;
    }
    25% {
      transform: scale(0.7);
      opacity: 0.7;
    }
    50% {
      transform: scale(0.8);
      opacity: 0.8;
    }
    75% {
      transform: scale(0.9);
      opacity: 0.9;
    }
    100% {
      transform: scale(1);
      opacity: 0.0;
    }
  }
  @-webkit-keyframes "warn" {
    0% {
      -webkit-transform: scale(0.6);
      opacity: 0.6;
    }
    25% {
      -webkit-transform: scale(0.7);
      opacity: 0.7;
    }
    50% {
      -webkit-transform: scale(0.8);
      opacity: 0.8;
    }
    75% {
      -webkit-transform: scale(0.9);
      opacity: 0.9;
    }
    100% {
      -webkit-transform: scale(1);
      opacity: 0.0;
    }
  }
</style>
