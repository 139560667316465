<template>
  <!-- 最外层div -->
  <div class="all" ref="all">
    <!-- 上层div -->
    <div class="up">
      <!-- 标题部分 -->
      <Htitle/>
    </div>      <!-- 上层div over-->


    <!-- 下层div -->
    <div class="down" >
      <!-- 下左侧 -->
      <div class="left">
        <!-- 月度交易电量 -->
        <div >
          <LeftF/>
        </div> <!-- 月度交易电量 over-->
        <!-- 交易品种占比分析 -->
        <div>
          <LeftS/>
        </div> <!-- 交易品种占比分析 over-->
       <!-- 客户签约电量密度分布 -->
        <div>
          <LeftT/>
        </div> <!-- 客户签约电量密度分布 over-->

      </div>      <!-- 下左侧 over-->

      <!-- 下中 -->
      <div class="center" >
        <!-- 签约电量/客户 -->
        <div>
          <CenterF/>
        </div> <!-- 签约电量/客户 over-->
        <!-- 地图 -->
        <div >
          <Map/>
        </div> <!-- 地图 over-->
      </div>      <!-- 下中 over-->

      <!-- 下右侧 -->
      <div class="right">
        <!-- 合同价格分析 -->
        <div>
          <RightF/>
        </div > <!-- 合同价格分析 over-->
        <!-- 月度售电利润 -->
        <div>
          <RightS/>
        </div> <!-- 月度售电利润 over-->
        <!-- 各行业用户签约电量分析 -->
        <div>
          <RightT/>
        </div> <!-- 各行业用户签约电量分析 over-->

      </div>      <!-- 下右侧 over-->

    </div>      <!-- 下层div over-->

  </div>
  <!-- 最外层div over-->
</template>


<script>
// 引入标题子组件
import Htitle from "../components/Home/Title.vue"
// 左一
import LeftF from "../components/Home/LeftF.vue"
// 左中
import LeftS from "../components/Home/LeftS.vue"
//左下
import LeftT from "../components/Home/LeftT.vue"
//中上
import CenterF from "../components/Home/CenterF"
//中间地图
import Map from "../components/Home/Map"
//右上
import RightF from "../components/Home/RightF"
//右中
import RightS from "../components/Home/RightS"
//右下
import RightT from "../components/Home/RightT"

export default {
  components: {
    // 配置为子组件
    Htitle,
    LeftF,
    LeftS,
    LeftT,
    CenterF,
    Map,
    RightF,
    RightS,
    RightT
  },
  data(){
    return{

    }
  },
  mounted(){
    //修改当前页面title
    document.title="江苏省售电交易平台"

    //如果按下F11 高度大于1080(取到的是数值型) 则修改最外层div的高为充满屏幕
    window.onkeyup=(e)=>{
      // console.log(window.innerHeight);//1080
      // console.log(window.innerWidth);//1366  //1920
      //console.log(this.$refs.all.offsetHeight);
      if(e.keyCode==122&&window.innerHeight>this.$refs.all.offsetHeight){
        this.$refs.all.style.height=`${window.innerHeight}px`
      }
    }
  }

}
</script>

<style scoped>
  .all{
  /* 全屏高度为高1080px  宽100% 1100*/
  /* 设置全局高度 app.vue高度由此撑开 */
  height: 1080px;
  /* 若实际全屏后更宽 则内容居中显示 */
  width: 1914px;
  margin: 0 auto;
  /* 添加全局背景(app width只是自动为当前视口100%) */
  background-image: url("../static/image/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  }
  /* 1.布局 */
  /* 布局中上下采用百分比 防止页面长高后变形 内层均采用定高 */
  .up{
    height: 7.3%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* border: 1px solid #fff; */
  }
  .down{
    height: 92.7%;
    display: flex;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* border:2px solid pink; */
    padding-top: .9rem;
  }
  .left,.right{
    flex-basis: 26.6%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    display: flex;
    flex-direction: column;
    /*border:2px solid rgb(189, 20, 223);*/
    justify-content: space-around;
  }
  .left{
    padding-left: 1.25rem;
  }
  .right{
    padding-right: 1.25rem;
  }
  .center{
    flex-basis: 46.8%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    display: flex;
    flex-direction: column;
    /* border: 1px solid rgb(245, 241, 10); */
    padding-bottom: 1.78rem ;
  }
  /* 左侧 */
  .left>div:first-child{
    flex-basis: 33.3%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  .left>div:nth-child(2){
    flex-basis: 33.3%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  .left>div:nth-child(3){
    flex-basis: 33.3%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  /* 中间 */
  .center>div:first-child{
    flex-basis: 11.6%;
    /* border: 1px solid rgb(8, 230, 27); */
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  .center>div:nth-child(2){
    flex-basis: 88.4%;
    /* border: 1px solid rgb(238, 13, 208); */
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  /* 右侧 */
  .right>div:first-child{
    flex-basis: 33.3%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  .right>div:nth-child(2){
    flex-basis: 33.3%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  .right>div:nth-child(3){
    flex-basis: 33.3%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  /* 2.内容 */


</style>


