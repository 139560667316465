<template>
<!-- 最外层div -->
  <div class="left-second">
    <!-- 标题div -->
    <div>
      <p>交易品种占比分析</p>
      <p>{{item.time}}</p>
    </div>      <!-- 标题div over-->
    <!-- 内容div -->
    <div >
      <!-- 内容左侧 -->
      <div class="left-second-left">
        <!-- 左侧框 -->
        <div>
          <li>
            <p><span></span>年度长协(万kWh)</p>
            <p style="color: #9CC4CC;font-size: 20px">{{item.changxie}}</p>
          </li>
          <li>
            <p><span></span>月度竞价(万kWh)</p>
            <p style="color: #9CC4CC;font-size: 20px">{{item.jingjia}}</p>
          </li>
          <li>
            <p><span></span>挂牌交易(万kWh)</p>
            <p style="color: #9CC4CC;font-size: 20px">{{item.guapai}}</p>
          </li>
          <li>
            <p><span></span>合约转让(万kWh)</p>
            <p style="color: #9CC4CC;font-size: 20px">{{item.heyue}}</p>
          </li>
        </div>    <!-- 左侧框 over-->


      </div>    <!-- 内容左侧 over-->

      <!-- 内容右侧 -->
      <div class="left-second-right">
        <LeftBetweenEcharts :item="item" :key="trem" v-if="item.changxie"></LeftBetweenEcharts>
      </div>    <!-- 内容右侧 over-->

    </div>      <!-- 内容div over-->
  </div>
<!-- 最外层div over-->
</template>
<script>
import LeftBetweenEcharts from '../echarts/LeftBetweenEcharts'
import * as method from "@/api";
export default {
  components:{
    LeftBetweenEcharts
  },
  props:{
  },
  data(){
    return{
      item:{},
      trem: 1
    }
  },
  mounted() {
    this.tradev()
    let vm = this
    setInterval(function (){
      vm.trem++;
    },3000)
  },
  methods:{
    tradev() {
      method.getMethod('/sdyw/dashboard/trade', {
      }).then(res => {
        this.item = res
        this.item = {
          changxie: 60000,
          guapai: 3000,
          heyue: 2000,
          jingjia: 10000,
          time: "2021.01.01-2021.01.31",
          total: 75000
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .left-second{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* 定高 */
    height: 20.2rem;
  }
  /* 标题 */
  .left-second>div:first-child{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* border: 1px solid rgb(243, 245, 242); */
    /* 定高 */
    height: 2.125rem;
    color: #fff;
    font-size: .875rem;
    display: flex;
    line-height: 2.125rem;
    justify-content: space-between;
    padding:0rem 1.1rem 0rem ;
    background-color: rgba(58, 64, 92, 0.6);
  }
  .left-second>div:first-child>p:nth-child(1){
    color: #04EBF9;
  }
  .left-second>div:first-child>p:nth-child(2){
    color: #ACD8E1;
  }
  /* 内容 */
  .left-second>div:nth-child(2){
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* 定高 */
    height: 16.5rem;
    margin-top: .8rem;
    display: flex;
  }
  .left-second-left{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    flex-basis: 46.5%;
    color: #3F6979;
    font-size: 12px;
  }
  .left-second-left div:nth-of-type(1){
    text-align: left;
    padding: 10px 0 0 20px;
    li{
      list-style: none;
      margin-top: 2px;
      p:nth-of-type(2){padding-left: 24px;}
    }
    p{
      line-height: 26px;
    }
    span{
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 12px;
    }
    li:nth-of-type(1) span{background: #135D52;}
    li:nth-of-type(2) span{background: #C1815D;}
    li:nth-of-type(3) span{background: #3F6979;}
    li:nth-of-type(4) span{background: #47327F;}
  }
  .left-second-right{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    flex-basis: 53.5%;
  }
  /* 内容内部 */
  .left-second-left>div{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /*定宽 */
    width: 13.5rem;
  }

  .left-second-left>div:first-child{
    background-image: url("../../static/image/left/交易品种.png");
    background-repeat: no-repeat;
    /* 定高 */
    height: 15.8rem;
    margin-top: .5rem;
  }
</style>
