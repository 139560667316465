<template>
<!-- 最外层div -->
  <div class="right-second">
    <!-- 标题div -->
    <div>
      <p>月度售电利润</p>
      <p>{{item.time}}</p>
    </div>      <!-- 标题div over-->
    <!-- 内容div -->
    <div >
      <!-- 内容左侧 -->
      <div class="right-second-left">

        <RightBetweenEcharts :profits="item.profits" :key="trem" v-if="item.profits"></RightBetweenEcharts>
      </div>    <!-- 内容左侧 over-->

      <!-- 内容右侧 -->
      <div class="right-second-right">
         <!-- 右侧框 -->
        <div>
          <li>
            <p>累计售电利润(万元)</p>
            <p style="color: #9CC4CC;font-size: 20px">{{item.total}}</p>
          </li>
          <li>
            <p>{{item.maxMonth}} 月售电利润最高(万元)</p>
            <p style="color: #9CC4CC;font-size: 20px">{{item.max}}</p>
          </li>
          <li>
            <p>{{item.minMonth}}  月售电利润最低(万元)</p>
            <p style="color: #9CC4CC;font-size: 20px">{{item.min}}</p>
          </li>
        </div>    <!-- 右侧框 over-->
      </div>    <!-- 内容右侧 over-->

    </div>      <!-- 内容div over-->
  </div>
<!-- 最外层div over-->
</template>
<script>
import RightBetweenEcharts from '../echarts/RightBetweenEcharts'
import * as method from "@/api";
export default {
  components:{
    RightBetweenEcharts
  },
  props:{
  },
  data(){
    return{
      item:{},
      trem:1
    }
  },
  mounted() {
    this.profit()
    let vm = this
    setInterval(function (){
      vm.trem++;
    },3000)
  },
  methods:{
    profit() {
      method.getMethod('/sdyw/dashboard/month/profit', {
      }).then(res => {
        this.item = res
        this.item = {
          max: 1079.472,
          maxMonth: "2",
          min: 540.472,
          minMonth: "1",
          profits: [540.472 ,879.0 ,0,0,0,0,0,0,0,0,0,0],
          time: "2021.01.01-2021.01.31",
          total: 1619.944
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .right-second{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* 定高 */
    height: 20.2rem;
  }
  /* 标题 */
  .right-second>div:first-child{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* border: 1px solid rgb(243, 245, 242); */
    /* 定高 */
    height: 2.125rem;
    color: #fff;
    font-size: .875rem;
    display: flex;
    line-height: 2.125rem;
    justify-content: space-between;
    padding:0rem 1.1rem 0rem ;
    border-left:3px solid #04EBF9;
    background-color: rgba(58, 64, 92, 0.6);
  }
  .right-second>div:first-child>p:nth-child(1){
    color: #04EBF9;
  }
  .right-second>div:first-child>p:nth-child(2){
    color: #ACD8E1;
  }
  /* 内容 */
  .right-second>div:nth-child(2){
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* 定高 */
    height: 16.5rem;
    margin-top: .8rem;
    display: flex;
  }
  .right-second-left{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    flex-basis: 53.5%;
  }
  .right-second-right{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    flex-basis: 46.5%;
    color: #738F9B;
    font-size: 12px;
  }
  .right-second-right div{
    text-align: left;
    padding: 10px 20px 0 20px;
    li{
      list-style: none;
      margin-top: 20px;
    }
    li:nth-of-type(2){margin-top: 18px;}
    p{
      line-height: 26px;
    }
  }
  /* 内容内部 */
  .right-second-right>div{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /*定宽 */
    width: 13.5rem;
  }

  .right-second-right>div:first-child{
    background-image: url("../../static/image/right/售电利润.png");
    background-repeat: no-repeat;
    /* 定高 */
    height: 15.8rem;
    margin-top: .5rem;
  }
</style>
