import Vue from 'vue'

import router from './router'
import store from './store'
// 引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import less from 'less'
Vue.use(less)
Vue.use(ElementUI);
import App from './App.vue'
// 引入axios
import axios from 'axios';
// axios.defaults.baseURL="";
Vue.prototype.axios=axios;
// 引入Echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
